import React, {useContext, useRef, useState} from "react";
import axios from "axios";
import PhoneNotFoundModal from "../modals/PhoneNotFoundModal";
import ProjectContext from "../../context/ProjectContext";
import {Link, useNavigate} from "react-router-dom";
import {LocalStorage} from "../../app/helpers";
import { resendCode } from "../../services/otp";
import {imgPrefix} from "../../constants/global";
import {globalConstants} from "../../services/globalVariables";

const BASEURL = globalConstants.BASE_URL;
const OTP = ({phoneNumber, addError, setSubmissionSuccessful, addSuccess, toDlc}) => {
    const submitRef = useRef();
    const [showPhoneNotFoundPopup,setShowPhoneNotFoundPopup ] = useState(false)

    const [otpInput, setOtpInput] = useState("");
    const {handleUser} = useContext(ProjectContext);
    const navigate = useNavigate();
    const saveTokensToLocalStorage = ({
                                          refreshToken,
                                          accessToken,
                                          user,
                                      }) => {
        LocalStorage.setRefreshToken(refreshToken);
        LocalStorage.setAccessToken(accessToken);
        LocalStorage.setUser(JSON.stringify(user));
        LocalStorage.setCompany(user.company);
    };
    const getTokensFromLocalStorage = () => {
        const refreshToken = LocalStorage.getRefreshToken();
        const accessToken = LocalStorage.getAccessToken();
        const company = LocalStorage.getCompany();
        return {refreshToken, accessToken, company};
    };

    const handleSubscriptionStatus = (subscriptionStatus) => {
        if (subscriptionStatus === "FREE_TRIAL" || subscriptionStatus === "PAID") {
            // Show either free trial or paid page
        } else if (subscriptionStatus === "TRIAL_EXPIRED") {
            // Show Trial Expired page
        } else if (subscriptionStatus === "PAYMENT_ERROR") {
            // Show Payment Method Failed page
        } else if (subscriptionStatus === "ACCOUNT_DISABLED") {
            // Show Login Failed page for ACCOUNT_DISABLED
        }
    };


    const handleInputChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 4) {
            setOtpInput(value);
        }

        if (value.length === 4) {
            setTimeout(() => {
                submitRef.current.click();
            },100)
        }
    };

    const emptyInput = () => {
        setOtpInput('');
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const request = {
                code: otpInput,
                login: phoneNumber,
            };

            const response = await axios.post(
                `${BASEURL}public/v1/user/otp/verify_login?loginType=PORTAL`,
                request
            );
            if (response.status === 200 || response.status === 201) {
                saveTokensToLocalStorage({
                    refreshToken: response.data.refreshToken,
                    accessToken: response.data.accessToken,
                    role: response.data.role,
                    company: response.data.company,
                    user: response.data.user,
                });
                handleUser(response.data.user,)
                if(response.status === 201){
                    addSuccess('User successfully created');
                }
                if(toDlc  !== true){
                    navigate('/billing');
                }
            } if(response.status === 401){
                emptyInput();
                addError('Error:  Code is wrong');
                }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            if (
                error.response &&
                error.response.status === 503 ){
                setShowPhoneNotFoundPopup(true)

            }else{
                addError('Error: Something is wrong please try again');
            }
            emptyInput();
        }
    }

    const handleResendCode = async (values) => {
        try {
            const requestBody = {
                login: phoneNumber,
            };

            const response = await resendCode(requestBody);

            if (response.status !== 200) {
                addError("Response data:", response.data);
            }
        } catch (error) {
            console.log(error)
            addError("Error: " + (error?.response?.data?.message || error.message));
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-5">
                <h1 className="title">Enter code</h1>
                <span className="mb-2">Please enter the code sent to</span>
                <div className="d-flex align-items-center justify-content-center">
                    <div>{phoneNumber}</div>
                        <img onClick={() => setSubmissionSuccessful(false)} src={imgPrefix + '/images/login/otp.svg' } alt="otp-svg" className="ps-2"/>
                </div>
                <div className="otp-input-code">
                    <input
                        type="text"
                        className={`otp-input`}
                        placeholder="0000"
                        name="phoneInput"
                        value={otpInput}
                        onInput={handleInputChange}
                        // onKeyDown={(e) => e.code == '13' && handleSubmit() }
                    />
                    <input type="submit" ref={submitRef} style={{ display: 'none' }} />
                    <button
                        type="button"
                        onClick={handleResendCode}
                        className="btn button-secondary otp-input-button"
                    >
                        Send code again
                    </button>
                    {showPhoneNotFoundPopup && (
                        <PhoneNotFoundModal
                            show={showPhoneNotFoundPopup}
                        />
                    )}
                </div>
            </div>
        </form>
    );
};

export default OTP;
