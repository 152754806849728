import React, {useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {useProjectContext} from  "./context/ProjectContext"


import Billing from "./pages/Billing";
import {ProjectContext} from "./context/ProjectContext";
import PostSubscriptionErrorModal from "./components/PostSubscriptionErrorModal/PostSubscriptionErrorModal";
import Login from "./pages/Login";
import TrialEnded from "./pages/TrialEnded";
import NoPage from "./pages/NoPage";
import CallRecords from "./pages/CallRecords";
import Notices from "./components/notices/Notices";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import RegistrationDLC from "./pages/10DLCRegistration"


function App() {
    const [notices, setNotices] = useState({
        success: [],
        error: [],
        info: [],
        log: [],
    })

    const addError = error => {
        setNotices({...notices, error: [...notices.error, error]})
    }

    const addLog = log => {
        setNotices({...notices, log: [...notices.log, log]})
    }

    const addSuccess = success => {
        setNotices({...notices, success: [...notices.success, success]})
    }

    const { subscriptionStatus } = useProjectContext();
    const location = useLocation();
    const isCallRecordsPage = location.pathname === "/call-records/";

    return (
        <ProjectContext>
                <main className={isCallRecordsPage ? "call-records-page" : ""}>
                    {!isCallRecordsPage && <Header />}
                    <Notices notices={notices} setNotices={setNotices}/>
                    <article id="qara-content">
                        <Routes>
                            <Route path={'/'} element={<Login addSuccess={addSuccess} addError={addError} addLog={addLog}/>}/>
                            <Route path={'/login-trial'} element={<Login addSuccess={addSuccess} trial={true} addError={addError} addLog={addLog}/>}/>
                            <Route path={'/billing'} element={<Billing />} />
                            <Route path={'/10dlc-registration'} element={<RegistrationDLC />} />
                            <Route path={'/trialended'} element={<TrialEnded addError={addError} addLog={addLog}/>}/>
                            <Route path={'/login'} element={<Login addSuccess={addSuccess} addError={addError} addLog={addLog}/>}/>
                            <Route path={'/call-records'} element={<CallRecords/>}/>
                            <Route path="*" element={<NoPage/>}/>
                        </Routes>
                    </article>
                    {!isCallRecordsPage && <Footer />}
                    <PostSubscriptionErrorModal/>
                </main>
        </ProjectContext>);
}

export default App;
