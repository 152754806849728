import React, { useEffect, useState, useContext } from "react";
import PaymentFailed from "./PaymentFailed";
import TrialEnded from "./TrialEnded";
import LoginFailed from "./LoginFailed";
import BillingCards from "./BillingCards";
import TrialDays from "../components/trialDays/TrialDays";
import { useProjectContext } from "../context/ProjectContext";
import {LocalStorage} from "../app/helpers";
import {useNavigate} from "react-router-dom";



const Subscription = () => {
  const { subscriptionStatus } = useProjectContext();
  const navigate = useNavigate();


  if (subscriptionStatus === 401) {
    LocalStorage.setAccessToken();
    LocalStorage.setRefreshToken();
    LocalStorage.setUser();
    navigate('/');
  }

  if (subscriptionStatus === 200) {
      return <BillingCards />;
  }
  if (subscriptionStatus === 404) {
    // return <TrialEnded />;
    return <TrialDays />;
  }
  if (subscriptionStatus === "PAYMENT_ERROR") {
    return <PaymentFailed />;
  }
  if (subscriptionStatus === "ACCOUNT_DISABLED") {
    return <LoginFailed />;
  }

  // return <div>Error Subscription</div>;
};

export default Subscription;
