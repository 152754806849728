import React, {useEffect, useState} from 'react';
import CompanyRegistrationDLC from "../components/companyRegistrationDLC/CompanyRegistrationDLC";
import UseCaseRegistrationDLC from "../components/useCaseRegistrationDLC/UseCaseRegistrationDLC";
import infoIcon from "../assets/images/svg/info-icon.svg";
import {LocalStorage} from "../app/helpers";
import {useProjectContext} from "../context/ProjectContext"
import Login from "../pages/Login"
import {getSubscription, getTrialDaysLeft} from "../services/billing";
import LoginFailed from "./LoginFailed";
import BillingCards from "./BillingCards";


const RegistrationDLC = () => {
    const [companyStatus, setCompanyStatus] = useState("UNVERIFIED");
    const [useCaseStatus, setUseCaseStatus] = useState("UNVERIFIED");
    const [companyId, setCompanyId] = useState();
    const { subscriptionStatus } = useProjectContext();

    const [loading, setLoading] = useState(true);
    const [type, setType] = useState(false);
    const {
        user,
        handleUser,
        handleSubscriptionType,
        handleSubscription,
        handleSubscriptionStatus,
        handletrialDaysLeft
    } = useProjectContext();

    const getSubscr = async () => {
        const company = localStorage.getItem("company");
        try {
            await getSubscription(company).then((response) => {
                handleSubscription(response.data);
                handleSubscriptionType(true);
                setType(true);
                setLoading(false);
                handleSubscriptionStatus(200);
            });
        } catch (ex) {
            if (ex.response.status === 404) {
                try {
                    const companyDetails = await getTrialDaysLeft(company)
                    handletrialDaysLeft(companyDetails?.data?.trialDaysLeft || 0)
                } catch(error) {
                    console.log('getTrialDaysLeft', error)
                }
                handleSubscriptionStatus(ex.response.status);
            } else {
                handleSubscriptionStatus(ex.response.data);
            }
            setType(false);
            setLoading(false);
        }
    };


    useEffect(() => {
        const company = LocalStorage.getCompany();
        setCompanyId(company);
    }, []);


    const handlecompanyStatus = (state) => {
        setCompanyStatus(state)
    }

    const handleuseCaseStatus = (state) => {
        setUseCaseStatus(state)
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "UNVERIFIED":
                return "red";
            case "PENDING":
                return "orange";
            case "VERIFIED":
                return "green";
            case "ACTIVE":
                return "green";
            case "REJECTED":
                return "red";
            default:
                return "gray";
        }
    };

    useEffect(() => {
        const getUserData = localStorage.getItem("userData");
        handleUser(JSON.parse(getUserData));
        getSubscr();
    }, []);

    if (loading) {
        return ;
    }

    if (user.role === "user") {
        return <LoginFailed />;
    }

    if (subscriptionStatus === 401) {
        return  <Login toDlc={true}/>
    }

    if (subscriptionStatus === 200) {
        return ( <section className="bg-light" style={{fontFamily: 'Poppins, sans-serif'}} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-4 d-flex align-items-center justify-content-between">
                            <h1 className="fw-semibold fs-2">10 DLC Registration</h1>
                            <div className="d-flex align-items-center justify-content-end gap-2">
                                <div>
                                    <span>Company </span>
                                    <span style={{color: getStatusColor(companyStatus)}}>{companyStatus}</span>
                                </div>
                                <span className="text-body-tertiary">|</span>
                                <div>
                                    <span>Use Case </span>
                                    <span style={{color: getStatusColor(useCaseStatus)}}>{useCaseStatus}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        {(companyStatus === "VERIFIED" || useCaseStatus === "VERIFIED") && (
                            <div className="alert d-flex alert-info border border-primary-subtle border-2 px-2 align-items-start"
                                 style={{width: "555px"}} role="alert">
                                <img
                                    width="20"
                                    height="20"
                                    src={infoIcon}
                                    className="mx-2 my-1"
                                    alt="info Icon"
                                    decoding="async"
                                />
                                <div>
                                    <span>If you need to make a change, please contact us by visiting</span>
                                    <span className={"d-block"}>Help & Support in the Qara app or by e-mailing</span>
                                    <a href="mailto:support@qara.app"
                                       className="link-underline-primary">support@qara.app</a>
                                </div>
                            </div>
                        )}
                        <CompanyRegistrationDLC setStatus={handlecompanyStatus} companyId={companyId}/>
                        <UseCaseRegistrationDLC setStatus={handleuseCaseStatus} isVisible={companyStatus} companyId={companyId}/>
                    </div>
                </div>
            </section>
        );
    }


};


export default RegistrationDLC;
